@media all {
    #local {
        h4.title {
            border-bottom: 1px solid $black;
            margin-bottom: 2rem;
        }

        button {
            color: $white;
        }
    }
}
