@media all {
    #newsletter {
        background: #00828c;
        color: $white;
        padding: 2rem 0 1.5rem 0;

        h3 {
            margin-top: 2px;
            text-transform: uppercase;
            font-size: $h5-font-size;
        }

        input {
            background: #00828c;
            border: 1px solid $white;
            color: $white;

            &::placeholder {
                color: $white;
            }
        }
        button {
            background: $white;
            color: #00828c;
        }
    }
}
