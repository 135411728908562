@media all {
    #homepage {
        .title {
            text-transform: uppercase;
        }
        .news {
            background-color: #f0f0f0;
            padding: 2rem 0 1rem 0;

            .title {
                color: #a6a6a6;
                span {
                    color: #6d6a6f;
                }
            }

            .news-table {
                display: table;
                margin-bottom: 2rem;
            }

            .news-row {
                display: table-row;
                .figure,
                .body {
                    vertical-align: top;
                    display: table-cell;
                    // margin-right: 1.5rem;
                }

                .body {
                    padding-left: 1rem;
                }

                h4 {
                    color: #4cafdb;
                    font-size: 1rem;
                }
            }
        }
    }
}
