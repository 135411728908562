// could be used to prevent server side cache
$random: "cache" + random(5000);

/* roboto-regular - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/roboto/roboto-v18-latin-regular.eot"); /* IE9 Compat Modes */
    src: local("Roboto"), local("Roboto-Regular"),
        url("../fonts/roboto/roboto-v18-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../fonts/roboto/roboto-v18-latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../fonts/roboto/roboto-v18-latin-regular.woff") format("woff"),
        /* Modern Browsers */
            url("../fonts/roboto/roboto-v18-latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../fonts/roboto/roboto-v18-latin-regular.svg#Roboto")
            format("svg"); /* Legacy iOS */
}
/* roboto-italic - latin */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    src: url("../fonts/roboto/roboto-v18-latin-italic.eot"); /* IE9 Compat Modes */
    src: local("Roboto Italic"), local("Roboto-Italic"),
        url("../fonts/roboto/roboto-v18-latin-italic.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../fonts/roboto/roboto-v18-latin-italic.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../fonts/roboto/roboto-v18-latin-italic.woff") format("woff"),
        /* Modern Browsers */ url("../fonts/roboto/roboto-v18-latin-italic.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../fonts/roboto/roboto-v18-latin-italic.svg#Roboto")
            format("svg"); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/roboto/roboto-v18-latin-700.eot"); /* IE9 Compat Modes */
    src: local("Roboto Bold"), local("Roboto-Bold"),
        url("../fonts/roboto/roboto-v18-latin-700.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../fonts/roboto/roboto-v18-latin-700.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../fonts/roboto/roboto-v18-latin-700.woff") format("woff"),
        /* Modern Browsers */ url("../fonts/roboto/roboto-v18-latin-700.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../fonts/roboto/roboto-v18-latin-700.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-700italic - latin */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 700;
    src: url("../fonts/roboto/roboto-v18-latin-700italic.eot"); /* IE9 Compat Modes */
    src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"),
        url("../fonts/roboto/roboto-v18-latin-700italic.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../fonts/roboto/roboto-v18-latin-700italic.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../fonts/roboto/roboto-v18-latin-700italic.woff")
            format("woff"),
        /* Modern Browsers */
            url("../fonts/roboto/roboto-v18-latin-700italic.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../fonts/roboto/roboto-v18-latin-700italic.svg#Roboto")
            format("svg"); /* Legacy iOS */
}

/* sanchez-regular - latin */
@font-face {
    font-family: "Sanchez";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/sanchez/sanchez-v5-latin-regular.eot"); /* IE9 Compat Modes */
    src: local("Sanchez"), local("Sanchez-Regular"),
        url("../fonts/sanchez/sanchez-v5-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../fonts/sanchez/sanchez-v5-latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../fonts/sanchez/sanchez-v5-latin-regular.woff") format("woff"),
        /* Modern Browsers */
            url("../fonts/sanchez/sanchez-v5-latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../fonts/sanchez/sanchez-v5-latin-regular.svg#Sanchez")
            format("svg"); /* Legacy iOS */
}

/* csc-icons */
@font-face {
    font-family: "icomoon";
    src: url("../fonts/icomoon.eot?"+$random);
    src: url("../fonts/icomoon.eot?"+$random+"#iefix")
            format("embedded-opentype"),
        url("../fonts/icomoon.ttf?"+$random) format("truetype"),
        url("../fonts/icomoon.woff?"+$random) format("woff"),
        url("../fonts/icomoon.svg?"++$random+"#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}
