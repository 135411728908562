table {
    thead {
        font-weight: bold;
        background: #d1e6df;
    }
    td {
        text-align: left;
        border-bottom: 1px solid #d1e6df;
        padding: 0.5rem 0.75rem;
    }

    tfoot {
        td {
            border: 0 none;
            font-size: 0.75rem;
            color: #6d6a6f;
        }
    }
}
