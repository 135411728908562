.appointments {
    padding: 3rem 0 0 0;
    section {
        header {
            border-bottom: 1px solid #6c6a6f;
            font-weight: bold;
            color: #6c6a6f;
        }

        .body {
            margin: 2rem 0 1rem 0;

            .btn {
                color: $white;
                width: 100%;
                text-align: center;
            }
        }

        footer {
            margin: 1rem 0 2rem 0;

            ul {
                padding: 0 0 0 0.75rem;
                list-style: none;
            }
        }
    }
}
