@media all {
    #main-menu {
        padding: 0.5rem 0;
        text-transform: lowercase;
        font-weight: bold;

        .homepage {
            position: absolute;
            color: #fff;
            display: block;
            padding-top: 0.5rem;
            text-decoration: none;
            font-size: 1.75rem;
        }

        & > ul {
            margin-left: 2rem !important;
        }

        ul {
            list-style: none;
            margin: 0;
            padding-left: 0;
        }

        li {
            display: block;
            color: $white;
            float: left;
            padding: 1rem;
            position: relative;
            font-size: 1rem;
            transition-duration: 0.5s;
            &:hover {
                cursor: pointer;
                background: #47a4ce;
            }

            li {
                color: #4cafdb;
                font-weight: normal;
                &:hover {
                    color: #fff;
                }

                &:first-of-type {
                    border-radius: 5px 5px 0 0;
                }
                &:last-of-type {
                    border-radius: 0 0 5px 5px;
                }
            }

            a {
                color: inherit;
                text-decoration: none;
            }

            ul {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                transition: all 0.5s ease;
                margin-top: 1rem;
                left: 0;
                display: none;
                z-index: 16;
                background: $white;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                border-radius: 5px;
                min-width: 300px;
            }

            li {
                i {
                    font-size: 1.75rem;
                    display: block;
                    position: absolute;
                }

                a {
                    margin-left: 2.5rem;
                    display: block;
                }
            }
        }

        ul li:hover > ul,
        ul li ul:hover {
            visibility: visible;
            opacity: 1;
            display: block;
        }

        ul li ul li {
            clear: both;
            width: 100%;
        }

        ul li ul li ul {
            display: none !important;
        }

        form {
        }

        input {
            border: 0 none;
            background: #82c7e5;
            color: $white;

            &::placeholder {
                color: #47a4ce;
            }
        }
        button {
            color: $white;
            padding: 0;
            margin: 0;

            & > i {
                font-size: 2rem;
            }
        }
    }
    
}



// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
    #main-menu{
        .homepage{
            top:0;
        }
        ul{
            width: 100%;
        }

        li{ 

            &:hover{
                background: transparent;
            }
                
            float: none;
            background: transparent;
            ul{
                transition: width 1s ease;  
                position: relative;
                box-shadow: 0 0 0 0 transparent;
                border-radius: 0;
                min-width: 300px;
            }
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {

    #main-menu li {
        padding: .6rem;


    }

    .se{
        transition: width .55s ease;
        width: 32px;
        display: none;

        .form-inline{
            position: relative;
            input[type=search]{
                position: absolute;
                width: 32px;
                height: 32px;
                float: right;
                left:0;
            }

            .btn{
                position: absolute;
            }
        }

        &:hover{
            width: 300px;
        }
    }
}