/**
 *
 */

#header {
    img {
        display: block;
        width: 100%;
    }

    .fillet {
        background-color: #c9c9cc;
        color: $white;
        //padding-bottom: 0.3rem;

        .login {
            background-color: $primary;
        }
    }

    .login {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                padding: 0.25rem 0 0.25rem 0;
            }
        }

        a {
            display: block;
            width: 100%;
            height: 100%;

            &::before {
                display: inline-block;
                content: "> ";
                margin-left: 1em;
                font-weight: bolder;
            }
        }
        .login-table {
            display: table;
            text-transform: lowercase;
            height: 100%;
            & > div {
                display: table-cell;
                vertical-align: middle;
                width: 50%;
            }
        }
    }
}

.page {
    .breadcrums {
        background: #f0f0f0;
        padding: 0.75rem 0;

        .breadcrumb {
            background: transparent;
            margin: 0;
            padding: 0;
        }
    }
}

#footer {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .card {
        background-color: #f0f0f0;
        border: 1px solid #979797;
        display: table;
        height: 100%;
        width: 100%;

        &:before {
            background-color: #a6a6a6;
            border: 1px solid #a6a6a6;
            border-radius: 50%;
            color: $white;
            display: block;
            font-family: $font-family-icons;
            font-size: 2rem;
            height: 50px;
            left: 50%;
            line-height: 50px;
            margin: -25px 0 0 -25px;
            position: absolute;
            width: 50px;

            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            // line-height: 1;

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .card-title {
            font-size: 1rem;
            font-weight: bold;
            font-family: "Roboto", sans-serif;
        }

        .card-body {
            padding: 3rem 1rem 1rem 1rem;
            display: table-cell;
            vertical-align: top;
            font-family: "Roboto", sans-serif;
            line-height: 1.5;
        }
    }

    .normas {
        color: #6c6a6f;
        margin-bottom: 2rem;
        font-size: 0.875rem;

        hr {
            border-top-color: #6c6a6f;
        }

        figure {
            margin-right: 1rem;
            float: left;
        }
    }

    .header {
        h2 {
            color: #6c6a6f;
            text-transform: uppercase;
            margin-bottom: 2rem;
            margin-top: 2rem;
        }
    }

    .body {
        background-color: $primary;
        color: $white;
        font-size: 0.875rem;

        h3 {
            display: block;
            width: 100%;
            border-bottom: 1px solid $white;
            display: block;
            font-size: 1rem;
            font-weight: bold;
            padding: 1rem 0;
            margin-bottom: 1rem;
        }

        a {
            color: $white;
        }

        li {
            &:before {
                content: "> ";
                display: inline-block;
            }
        }
    }
    .footer {
        background-color: #6c6a6f;
        color: $white;
        padding: 1rem 0;
    }
}
