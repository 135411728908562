@media all {
    #login {
        .page {
            header {
                padding: 2rem 0;
                background: #dceef8 url("../images/login.jpg") 50% 0 no-repeat;
                background-size: contain;

                &.logged {
                    background-image: none;
                }

                &.not-logged {
                    ul {
                        text-align: left;
                        list-style: none;
                        padding-left: 0;
                    }
                }
            }

            form {
                width: 75%;
                margin: 0 auto;

                .btn {
                    width: 100%;
                    color: $white;
                }
            }

            h2 {
                color: #a6a6a6;
                text-transform: uppercase;
                margin-bottom: 1.5rem;
                span {
                    font-weight: bold;
                }
            }

            .schedule {
                padding: 3rem 0;

                p {
                    border-bottom: 1px solid #a6a6a6;
                }

                form {
                    width: 50%;
                    margin: 2rem auto 0 auto;

                    select {
                        width: 100%;
                    }
                }
            }

            .appointments {
                background: #f1f1f2;
                padding: 3rem 0;

                table {
                    width: 100%;

                    thead {
                        background: #dcddde;
                        text-transform: uppercase;
                    }

                    tbody {
                        tr {
                            td {
                                color: #a7a9ac;
                            }
                            &.mark-off {
                                td {
                                    color: #6c6a6f;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }

            .info {
                margin: 1.5rem auto;
                width: 75%;
            }

            .appointments-list {
                p {
                    border: 0 none !important;
                }

                .alert {
                    margin: 0 auto;
                }
            }
        }

        .table {
            form {
                margin: 0 !important;
                padding: 0;
                width: 100% !important;
            }
            .bto-submit {
                border: 0 none;
                background-color: transparent;
                text-transform: uppercase;
                color: #4cafdb;
                margin: 0 auto;
                display: block;
            }
        }
    }
}


// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
    #login {
        .page {
            header {
                background-image: none;
            }
        }
    }
}