@media all {
    .articles {
        &.news {
            background-color: inherit;
            padding: 3rem 0 1.5rem 0;

            .title {
                color: #a6a6a6;
                span {
                    color: #6d6a6f;
                }
            }

            .news-table {
                display: table;
                margin-bottom: 3rem;
            }

            .news-row {
                display: table-row;
                .figure,
                .body {
                    vertical-align: top;
                    display: table-cell;
                    // margin-right: 1.5rem;
                }

                .body {
                    padding-left: 1rem;
                }

                h4 {
                    color: #4cafdb;
                    font-size: 1rem;
                }
            }
        }
    }
}
