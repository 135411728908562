@media all {
    #contact {
        h4.title {
            border-bottom: 1px solid $black;
            margin-bottom: 2rem;
        }

        button {
            color: $white;
        }

        .map {
            margin: 0;
        }

        .blue {
            background: #4cafdb;
            color: $white;

            a {
                color: $white;
            }
        }
    }
}
