@media all {
    #noticia {
        .title {
            background: #f0f0f0;
            //text-transform: uppercase;
            color: #4cafdb;
            font-family: $font-family-serif;
        }

        .article {
            header {
                border-bottom: 1px solid #f0f0f0;
                padding: 0 0 1rem 0;
                margin-bottom: 1rem;
            }

            &.row {
                border-bottom: 1px solid #f0f0f0;
            }
        }
    }
}
