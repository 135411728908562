@media all{
    .slideshow {
        .slideshow-content-wrapper {
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            background: transparent;
            width: 50%;
            height: 100%;
        }
    
        .card {
            background: transparentize($white, 0.3);
            border: 0 none;
            padding: 3rem 2rem;
            text-align: center;
            width: 531px;
            text-transform: uppercase;
            margin: 0 auto;
    
            hr {
                width: 50%;
                margin: 0 auto;
            }
    
            .card-title {
                font-family: "Sanchez", serif;
                font-size: 2rem;
                color: #4cafdb;
                word-wrap: normal;
            }
    
            .card-body {
            }
    
            &:before {
                background-color: #4cafdb;
                border: 1px solid #4cafdb;
                border-radius: 50%;
                color: $white;
                display: block;
                font-family: $font-family-icons;
                font-size: 2rem;
                height: 60px;
                left: 50%;
                line-height: 60px !important;
                margin: -30px 0 0 -30px;
                position: absolute;
                width: 60px;
                top: 0;
    
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
    
                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
    
            &.a-sua-estadia-ico:before {
                content: "\e900";
            }
            &.infiltracoes-ico:before {
                content: "\e901";
            }
            &.obstreticia-ico:before {
                content: "\e902";
            }
            &.ortopedia-ico:before {
                content: "\e903";
            }
            &.publicacoes-ico:before {
                content: "\e904";
            }
            &.terapia-fala-ico:before {
                content: "\e905";
            }
            &.acunpunctura-ico:before {
                content: "\e906";
            }
            &.andrologia-ico:before {
                content: "\e907";
            }
            &.apoio-paciente-ico:before {
                content: "\e908";
            }
            &.apresentacao-servicos-clinicos-ico:before {
                content: "\e909";
            }
            &.apresentacao-ico:before {
                content: "\e90a";
            }
            &.atendimento-permanente-ico:before {
                content: "\e90b";
            }
            &.aviso-ico:before {
                content: "\e90c";
            }
            &.bloco-operatorio-ico:before {
                content: "\e90d";
            }
            &.cardiologia-ico:before {
                content: "\e90e";
            }
            &.centro-medicina-fisica-reabilitacao-ico:before {
                content: "\e90f";
            }
            &.cirurgia-geral-ico:before {
                content: "\e910";
            }
            &.cirurgia-ortopedica-ico:before {
                content: "\e911";
            }
            &.cirurgia-plastica-ico:before {
                content: "\e912";
            }
            &.cirurgia-vascular-ico:before {
                content: "\e913";
            }
            &.clinica-geral-ico:before {
                content: "\e914";
            }
            &.condicoes-gerais-ico:before {
                content: "\e915";
            }
            &.consulta-da-dor-ico:before {
                content: "\e916";
            }
            &.contactos-ico:before {
                content: "\e917";
            }
            &.convencoes-ico:before {
                content: "\e918";
            }
            &.dermatologia-ico:before {
                content: "\e919";
            }
            &.endocrinologia-ico:before {
                content: "\e91a";
            }
            &.entidades-protocoladas-ico:before {
                content: "\e91b";
            }
            &.erro-ico:before {
                content: "\e91c";
            }
            &.especialidade-ico:before {
                content: "\e91d";
            }
            &.estatutos-regulamentos-ico:before {
                content: "\e91e";
            }
            &.exames-complementares-diagnostico-ico:before {
                content: "\e91f";
            }
            &.gastroentologia-ico:before {
                content: "\e920";
            }
            &.ginecologia-ico:before {
                content: "\e921";
            }
            &.historia-ico:before {
                content: "\e922";
            }
            &.homepage-ico:before {
                content: "\e923";
            }
            &.imunoalergologia-ico:before {
                content: "\e924";
            }
            &.informacao-ico:before {
                content: "\e925";
            }
            &.internamento-clinico-ico:before {
                content: "\e926";
            }
            &.localizacao-acessos-ico:before {
                content: "\e927";
            }
            &.medicina-dentaria-ico:before {
                content: "\e928";
            }
            &.medicina-infantil-ico:before {
                content: "\e929";
            }
            &.medicina-interna-ico:before {
                content: "\e92a";
            }
            &.medicos-ico:before {
                content: "\e92b";
            }
            &.menu-ico:before {
                content: "\e92c";
            }
            &.missao-valores-ico:before {
                content: "\e92d";
            }
            &.neurocirurgia-ico:before {
                content: "\e92e";
            }
            &.noticias-ico:before {
                content: "\e92f";
            }
            &.nutricao-ico:before {
                content: "\e930";
            }
            &.objectivos-ico:before {
                content: "\e931";
            }
            &.oftalmologia-ico:before {
                content: "\e932";
            }
            &.organizacao-ico:before {
                content: "\e933";
            }
            &.orgaos-sociais-ico:before {
                content: "\e934";
            }
            &.ortodontia-ico:before {
                content: "\e935";
            }
            &.otorrino-ico:before {
                content: "\e936";
            }
            &.pediatria-ico:before {
                content: "\e937";
            }
            &.pneumologia-ico:before {
                content: "\e938";
            }
            &.podologia-ico:before {
                content: "\e939";
            }
            &.procurar-ico:before {
                content: "\e93a";
            }
            &.protocolos-convencoes-ico:before {
                content: "\e93b";
            }
            &.psicologia-ico:before {
                content: "\e93c";
            }
            &.psiquiatria-ico:before {
                content: "\e93d";
            }
            &.relatorio-contas-ico:before {
                content: "\e93e";
            }
            &.residencia-medica-ico:before {
                content: "\e93f";
            }
            &.reumatologia-ico:before {
                content: "\e940";
            }
            &.senalogia-ico:before {
                content: "\e941";
            }
            &.servicos-prestados-ico:before {
                content: "\e942";
            }
            &.tabela-precos-associado-ico:before {
                content: "\e943";
            }
            &.tabela-precos-particular-ico:before {
                content: "\e944";
            }
            &.tabela-precos-protocolos-ico:before {
                content: "\e945";
            }
            &.urologia-ico:before {
                content: "\e946";
            }
            &.utente-associado-ico:before {
                content: "\e947";
            }
            &.utente-particular-ico:before {
                content: "\e948";
            }
            &.utente-protocolado-ico:before {
                content: "\e949";
            }
        }
    }
    
}


// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
    .slideshow {
        .slideshow-content-wrapper{
            display: block;
            align-items: center;
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: initial;
            background: #d6eae5;
        }

        .card {
            background: #d6eae5;
            width: 100%;
            .card-title{
                font-size: 1.8rem;
            }
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}