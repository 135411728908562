/**
 *
 */

.bg-color {
    &.azul {
        background: #4cafdb;
        color: #fff;
    }
}

.row {
    &.pad1 {
        padding: 1rem 0;
    }

    &.pad2 {
        padding: 2rem 0;
    }

    &.pad3 {
        padding: 3rem 0;
    }

    &.pad4 {
        padding: 4rem 0;
    }
}

[class^="col"] {
    &.pad1 {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    &.pad2 {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    &.pad3 {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    &.pad4 {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}

ul {
    &.col2 {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        list-style-type: none;
        list-style-position: inside; //this is important addition
    }
}
